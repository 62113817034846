
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Edit",

  data: () => ({
    rules,
    model: {
      is_active: true as boolean
    } as any,
    disableSave: true as boolean,
    fullLoaded: false as boolean,
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  watch: {
    model: {
      deep: true,
      handler() {
        if (this.fullLoaded) {
          this.disableSave = false;
        }
      }
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.transportTypes().getForEdit(Number(to.params.id));
      next(vm => {
        vm.model = item;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          await this.$API
            .transportTypes()
            .edit(Number(this.$route.params.id), this.model);
          await this.$router.push("/transport-categories");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
